<template>
  <section class="mb-16 py-16 relative" ref="el">
    <ClientOnly>
      <Splide
        :has-track="false"
        :options="{
          speed: 500,
          perPage: isPhoneScreen ? 1 : showButtons ? 4 : 3,
          lazyLoad: true,
          gap: '1rem',
          perMove: 1,
          pagination: false,
          rewind: true,
          mediaQuery: 'min',
          breakpoints: {
            1440: {
              perPage: showButtons ? 4 : 3,
              gap: '2rem'
            },
            1024: {
              perPage: showButtons ? 4 : 3,
              gap: '2rem'
            },
            768: {
              perPage: 1,
              gap: '2rem'
            },
            425: {
              perPage: 1,
              perMove: 1,
              gap: '2rem'
            }
          }
        }"
        aria-label="My Favorite Images"
      >
        <SplideTrack>
          <template v-for="(activity, index) in productStore.categories" :key="index + 'cf'">
            <SplideSlide class="md:!min-w-[300px]">
              <LazyCardSlideActivity :name="activity.name" :slug="activity.slug" :images="activity.images" />
            </SplideSlide>
          </template>
        </SplideTrack>

        <div class="splide__arrows z-50">
          <button
            type="button"
            :class="{ '!hidden': !showButtons }"
            class="splide__arrow splide__arrow--next top-1/2 lg:top-1/3 z-10 !absolute !right-2 lg:-right-20 !bg-black !rounded-full !h-16 !w-16 !lg:w-48 !lg:h-48 flex items-center justify-center text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
          </button>
          <button
            type="button"
            :class="{ '!hidden': !showButtons }"
            class="splide__arrow splide__arrow--prev top-1/2 lg:top-1/3 !absolute z-10 !left-2 lg:-left-20 !bg-black !rounded-full !h-16 !w-16 !lg:w-48 !lg:h-48 flex items-center justify-center text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
          </button>
        </div>
      </Splide>
    </ClientOnly>
  </section>
</template>

<script lang="ts" setup>
import { useProductStore } from '~/stores/product'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'

const productStore = useProductStore()
import { useElementSize } from '@vueuse/core'

const el = ref(null)
const { width } = useElementSize(el)

const isLaptopScreen = computed(() => {
  return width.value >= 1024
})

const isPhoneScreen = computed(() => {
  return width.value <= 640
})

const showButtons = computed(() => {
  if (isLaptopScreen.value && productStore.categories.length <= 3) {
    return false
  }

  return true
})

const slidePerView = computed(() => {
  if (productStore.categories.length >= 6) {
    return 6.5
  }

  if (productStore.categories.length <= 5) {
    return 3.5
  }
})
</script>

<style></style>
